var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resultls"},[_c('div',{staticClass:"grid"},[_c('h1',{staticClass:"h1",staticStyle:{"grid-area":"header"}},[_vm._v(" "+_vm._s("$" + _vm.ticker.toUpperCase())+" ")]),_c('div',{staticClass:"box1"},[_c('router-link',{staticClass:"home link",staticStyle:{"grid-area":"header1","text-decoration":"none","color":"inherit"},attrs:{"to":"/"}},[_vm._v("🡰 Back")]),_c('i',{staticClass:"fas fa-cloud"})],1),_c('VueTradingView',{staticClass:"view",staticStyle:{"grid-area":"middle"},attrs:{"options":{
        theme: 'Dark',
        symbol: _vm.ticker,
        interval: 'D',
        showdetails: true,
        range: '60M',
        width: '100%',
        height: '500px',
      }}}),_c('div',{staticClass:"three",staticStyle:{"grid-area":"left"}},[_c('button',{staticClass:"r_button",on:{"click":function($event){return _vm.annual(_vm.ticker)}}},[_vm._v(" Annual Statement ")]),_c('button',{staticClass:"r_button",on:{"click":function($event){return _vm.quaterly(_vm.ticker)}}},[_vm._v(" Quarterly Statement ")]),_c('button',{staticClass:"r_button",on:{"click":function($event){return _vm.ir(_vm.ticker)}}},[_vm._v("Investor Relations")]),_c('button',{staticClass:"r_button",on:{"click":function($event){return _vm.funds(_vm.ticker)}}},[_vm._v("Share holders")]),_c('button',{staticClass:"r_button",on:{"click":function($event){return _vm.insider(_vm.ticker)}}},[_vm._v(" Insider Buying ")]),_c('button',{staticClass:"r_button",on:{"click":function($event){return _vm.twitter(_vm.ticker)}}},[_vm._v("Twitter")]),_c('button',{staticClass:"r_button",on:{"click":function($event){return _vm.seekingalpha(_vm.ticker)}}},[_vm._v(" Seeking Alpha Analysis ")]),_c('button',{staticClass:"r_button",on:{"click":_vm.kofi}},[_vm._v("Support💚")])]),_c('h1',{staticClass:"three",staticStyle:{"grid-area":"right"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }